<template>
  <div class="wrapper">
    <menu-ver :type="typeMenu" :name="typeMenu" />
    <div class="container m-3">
      <div class="table-header">
        <button
          class="btn button buttonCreate"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
          @click="getActions(0)"
        >
          <i class="fas fa-plus-circle"></i> {{ $t("actions.create") }}
        </button>
      </div>
      <table class="table" v-if="courses && courses.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("maintenance.languages.key") }}</th>
            <th scope="col">{{ $t("maintenance.languages.name") }}</th>
            <th scope="col" style="text-align: center">
              {{ $t("maintenance.courses.themes") }}
            </th>
            <th scope="col" style="text-align: center">
              {{ $t("maintenance.courses.students") }}
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in courses" :key="item.curso_id">
            <td>{{ item.curso_id }}</td>
            <td>{{ item.curso_titulo }}</td>
            <td style="text-align: center">{{ item.curso_temas }}</td>
            <td style="text-align: center">{{ item.curso_alumnos }}</td>
            <td>
              <button
                class="btn button buttonDelete ms-2"
                @click="getActions(2, item)"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
                :data-title="$t(`menu.borrar`)"
              >
                <i class="fas fa-trash"></i>
              </button>
              <button
                class="btn button buttonEdit ms-2"
                @click="getActions(1, item)"
                data-bs-toggle="modal"
                data-bs-target="#editModal"
                :data-title="$t(`menu.editar`)"
              >
                <i class="fas fa-edit"></i>
              </button>
              <button
                class="btn button buttonDelete ms-2"
                @click="seeTemas(item.curso_id)"
                :data-title="$t(`menu.vertemas`)"
              >
                <i class="fas fa-list"></i>
              </button>
              <button
                class="btn button buttonEdit ms-2"
                @click="seeMaterial(item.curso_id)"
                :data-title="$t(`menu.vermaterial`)"
              >
                <i class="fas fa-file"></i>
              </button>
              <button
                class="btn button buttonEdit"
                @click="seeCurse(item.curso_id)"
                :style="{ backgroundColor: colorCompany }"
                :data-title="$t(`menu.vercurso`)"
              >
                <i class="far fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>{{ $t("errors.3") }}</p>
    </div>
  </div>

  <!-- Modal add -->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-resp">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("maintenance.courses.addTitle", { name: `${cursoId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.languages.name`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="curso_titulo"
                :placeholder="$t(`maintenance.languages.name`) + '*'"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.superusers.email`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="email"
                class="form-control"
                v-model.trim="curso_email"
                :placeholder="$t(`maintenance.superusers.email`) + '*'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.superusers.password`) + " *"
              }}</label>
              <input
                v-if="visPas"
                type="password"
                class="form-control"
                v-model.trim="curso_clave"
                :placeholder="$t(`maintenance.superusers.password`) + '*'"
                maxlength="20"
              />
              <input
                v-else
                type="text"
                class="form-control"
                v-model.trim="curso_clave"
                :placeholder="$t(`maintenance.superusers.password`) + '*'"
                maxlength="20"
              />
              <i
                v-if="!visPas"
                @click="visPas = true"
                class="fas fa-eye-slash field-icon"
              ></i>
              <i
                v-else
                @click="visPas = false"
                class="fas fa-eye field-icon"
              ></i>
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mUser.courses.iniDate`)
              }}</label>
              <input
                style="text-transform: uppercase"
                type="date"
                class="form-control"
                v-model.trim="curso_inicio"
                placeholder="dd/mm/yyyy"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mUser.courses.finDate`)
              }}</label>
              <input
                style="text-transform: uppercase"
                type="date"
                class="form-control"
                v-model.trim="curso_fin"
                placeholder="dd/mm/yyyy"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`mUser.courses.amount`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="curso_max"
                :placeholder="$t(`mUser.courses.amount`)"
                maxlength="2"
              />
            </div>
            <div class="mb-3">
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-top: 5px; margin-right: 5px"
              >
                {{ $t("mUser.courses.web") }}
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                v-model="curso_web"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: 0px;
                  margin-right: 5px;
                "
              />
            </div>
            <div class="mb-3">
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-top: 5px; margin-right: 5px"
              >
                {{ $t("mUser.courses.advice") }}
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                v-model="curso_aviso"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: 0px;
                  margin-right: 5px;
                "
              />
            </div>
            <div v-if="curso_web">
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.category`)
                }}</label>
                <select
                  class="form-select inputForm"
                  aria-label="Elija una opción"
                  v-model.trim="curso_categoria_cd"
                >
                  <option
                    v-for="cate in categorias"
                    :key="cate.cate_id"
                    :value="cate.cate_id"
                  >
                    {{ cate.cate_nombre_ESP }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.duration`)
                }}</label>
                <input
                  style="text-transform: uppercase"
                  type="text"
                  class="form-control"
                  v-model.trim="curso_duracion"
                  :placeholder="$t(`mUser.courses.duration`)"
                  maxlength="50"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.price`)
                }}</label>
                <input
                  style="text-transform: uppercase"
                  type="text"
                  class="form-control"
                  v-model.trim="curso_precio"
                  :placeholder="$t(`mUser.courses.price`)"
                  maxlength="50"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.description`)
                }}</label>
                <textarea
                  v-model.trim="curso_descripcion"
                  class="form-control"
                  rows="7"
                  style="text-transform: uppercase"
                  maxlength="500"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.descriptionL`)
                }}</label>
                <textarea
                  v-model.trim="curso_desc_larga"
                  class="form-control"
                  rows="7"
                  style="text-transform: uppercase"
                  maxlength="500"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.knowledge`)
                }}</label>
                <textarea
                  v-model.trim="curso_conocimientos_previos"
                  class="form-control"
                  rows="7"
                  style="text-transform: uppercase"
                  maxlength="500"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{ $t(`languages.title`) }}</label>
                <select
                  class="form-select inputForm"
                  aria-label="Elija una opción"
                  v-model.trim="curso_idioma"
                >
                  <option value="ESP">{{ $t(`languages.es`) }}</option>
                  <option value="ENG">{{ $t(`languages.en`) }}</option>
                </select>
              </div>
            </div>

            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ messageError }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="addCourse"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal delete -->
  <div
    class="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("maintenance.courses.deleteTitle", { name: `${courseId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ $t("maintenance.courses.deleteMessage") }}
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            id="btnDelete"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="deleteCourse"
          >
            {{ $t("maintenance.actions.confirm") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal edit -->
  <div
    class="modal fade"
    id="editModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-resp">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("maintenance.courses.editTitle", { name: `${courseId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.languages.name`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="curso_titulo"
                :placeholder="$t(`maintenance.languages.name`) + '*'"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.superusers.email`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="email"
                class="form-control"
                v-model.trim="curso_email"
                :placeholder="$t(`maintenance.superusers.email`)"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.superusers.password`) + " *"
              }}</label>
              <input
                v-if="visPas"
                type="password"
                class="form-control"
                v-model.trim="curso_clave"
                :placeholder="$t(`maintenance.superusers.password`) + '*'"
                maxlength="20"
              />
              <input
                v-else
                type="text"
                class="form-control"
                v-model.trim="curso_clave"
                :placeholder="$t(`maintenance.superusers.password`) + '*'"
                maxlength="20"
              />
              <i
                v-if="!visPas"
                @click="visPas = true"
                class="fas fa-eye-slash field-icon"
              ></i>
              <i
                v-else
                @click="visPas = false"
                class="fas fa-eye field-icon"
              ></i>
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mUser.courses.iniDate`)
              }}</label>
              <input
                style="text-transform: uppercase"
                type="date"
                class="form-control"
                v-model.trim="curso_inicio"
                placeholder="dd/mm/yyyy"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mUser.courses.finDate`)
              }}</label>
              <input
                style="text-transform: uppercase"
                type="date"
                class="form-control"
                v-model.trim="curso_fin"
                placeholder="dd/mm/yyyy"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`mUser.courses.amount`) + " *"}}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="curso_max"
                :placeholder="$t(`mUser.courses.amount`) + ' *'"
                maxlength="2"
              />
            </div>

            <div class="mb-3">
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-top: 5px; margin-right: 5px"
              >
                {{ $t("mUser.courses.iWeb") }}
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                v-model="curso_web"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: 0px;
                  margin-right: 5px;
                "
              />
            </div>
            <div class="mb-3">
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-top: 5px; margin-right: 5px"
              >
                {{ $t("mUser.courses.advice") }}
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                v-model="curso_aviso"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: 0px;
                  margin-right: 5px;
                "
              />
            </div>
            <div v-if="curso_web">
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.category`)
                }}</label>
                <select
                  class="form-select inputForm"
                  aria-label="Elija una opción"
                  v-model.trim="curso_categoria_cd"
                >
                  <option
                    v-for="cate in categorias"
                    :key="cate.cate_id"
                    :value="cate.cate_id"
                  >
                    {{ cate.cate_nombre_ESP }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.duration`)
                }}</label>
                <input
                  style="text-transform: uppercase"
                  type="text"
                  class="form-control"
                  v-model.trim="curso_duracion"
                  :placeholder="$t(`mUser.courses.duration`)"
                  maxlength="50"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.price`)
                }}</label>
                <input
                  style="text-transform: uppercase"
                  type="text"
                  class="form-control"
                  v-model.trim="curso_precio"
                  :placeholder="$t(`mUser.courses.price`)"
                  maxlength="50"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.description`)
                }}</label>
                <textarea
                  v-model.trim="curso_descripcion"
                  class="form-control"
                  rows="7"
                  style="text-transform: uppercase"
                  maxlength="500"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.descriptionL`)
                }}</label>
                <textarea
                  v-model.trim="curso_desc_larga"
                  class="form-control"
                  rows="7"
                  style="text-transform: uppercase"
                  maxlength="500"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.knowledge`)
                }}</label>
                <textarea
                  v-model.trim="curso_conocimientos_previos"
                  class="form-control"
                  rows="7"
                  style="text-transform: uppercase"
                  maxlength="500"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{ $t(`languages.title`) }}</label>
                <select
                  class="form-select inputForm"
                  aria-label="Elija una opción"
                  v-model.trim="curso_idioma"
                >
                  <option value="ESP">{{ $t(`languages.es`) }}</option>
                  <option value="ENG">{{ $t(`languages.en`) }}</option>
                </select>
              </div>
            </div>

            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ messageError }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="editCourse"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
              <div class="modal-body" v-else-if="action === 2">
                {{ $t("errors.6") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button
            v-if="result === '0' || action === 2"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-if="action === 0 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#addModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="action === 1 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#editModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    MenuVer,
  },
  data() {
    return {
      courses: [
        // {
        //   curso_id: 1,
        //   curso_titulo: "aaa",
        //   curso_email: "ooo"
        // }
      ],
      course: {},
      curso_titulo: "",
      curso_email: "",
      curso_clave: "",
      curso_id: "",
      curso_temas: "",
      curso_alumnos: "",
      courseId: "",
      curso_web: 0,
      curso_aviso: 0,
      curso_categoria_cd: 0,
      curso_precio: "",
      curso_duracion: "",
      curso_descripcion: "",
      curso_desc_larga: "",
      curso_conocimientos_previos: "",
      curso_idioma: "ESP",
      curso_inicio: "",
      curso_fin: "",
      curso_max: "",
      result: "",
      action: 0,
      id: "",
      formError: false,
      loading: false,
      visPas: true,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      messageError: "",
      categorias: [],
      typeMenu: "",
      cursoId: "",
    };
  },
  created() {
    this.getCourses();
    this.changeBreadCrumb(15);
    this.bigDrop();
    this.getCategorias();

    if (window.location.href.indexOf("adminsu") > 0) {
      this.typeMenu = "superuser";
    } else {
      this.typeMenu = "company";
    }
  },
  computed: {
    ...mapState(["servidor"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getCourses() {
      try {
      } catch (error) {
        console.error(error);
      }
      const res = await axios.post(
        `${this.servidor}api/op.asp?tp=courses&ac=listado`
      );
      if (res.data) {
        this.courses = res.data.data;
      }
    },
    async getCategorias() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=courses&ac=categorias`
        );
        if (res.data) {
          this.categorias = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async addCourse() {
      this.result = "";
      if (this.validateForm()) {
        try {
          this.loading = true;
          // console.log("fecha", this.curso_inicio);
          // console.log("fecha", this.curso_fin);
          let course = {
            curso_titulo: this.curso_titulo.toUpperCase(),
            curso_email: this.curso_email.toUpperCase(),
            curso_clave: this.curso_clave,
            curso_categoria_cd: this.curso_categoria_cd,
            curso_precio: this.curso_precio,
            curso_duracion: this.curso_duracion,
            curso_descripcion: this.curso_descripcion,
            curso_desc_larga: this.curso_desc_larga,
            curso_conocimientos_previos: this.curso_conocimientos_previos,
            curso_idioma: this.curso_idioma,
            curso_inicio: this.formatDate(this.curso_inicio),
            curso_fin: this.formatDate(this.curso_fin),
            curso_max: this.curso_max,
            cursoId: "",
          };
          if (this.curso_web) {
            course.curso_web = 1;
          } else {
            course.curso_web = 0;
          }
          if (this.curso_aviso) {
            course.curso_aviso = 1;
          } else {
            course.curso_aviso = 0;
          }
          // console.log("POST", course);
          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=courses&ac=grabar`,
            course
          );
          // console.log("res", res);
          this.result = res.data.RESULT;
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
          this.result = "1";
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.loading = false;
      }
    },
    async editCourse() {
      this.result = "0";
      if (this.validateForm()) {
        try {
          this.loading = true;
          const course = {
            curso_id: this.curso_id,
            curso_titulo: this.curso_titulo.toUpperCase(),
            curso_email: this.curso_email.toUpperCase(),
            curso_clave: this.curso_clave,
            curso_categoria_cd: this.curso_categoria_cd,
            curso_precio: this.curso_precio,
            curso_duracion: this.curso_duracion,
            curso_descripcion: this.curso_descripcion,
            curso_desc_larga: this.curso_desc_larga,
            curso_conocimientos_previos: this.curso_conocimientos_previos,
            curso_idioma: this.curso_idioma,
            curso_inicio: this.formatDate(this.curso_inicio),
            curso_fin: this.formatDate(this.curso_fin),
            curso_max: this.curso_max
          };
          if (this.curso_web) {
            course.curso_web = 1;
          } else {
            course.curso_web = 0;
          }
          if (this.curso_aviso) {
            course.curso_aviso = 1;
          } else {
            course.curso_aviso = 0;
          }
          // console.log("PUT", course);
          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=courses&ac=actualizar`,
            course
          );
          // console.log("res", res);
          this.result = res.data.RESULT;
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          this.result = "1";
          console.error(error);
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.loading = false;
      }
    },
    async deleteCourse() {
      try {
        const borrado = {
          curso_id: this.id,
        };
        this.loading = true;
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=courses&ac=borrar`,
          borrado
        );
        this.result = res.data.RESULT;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    getId(event) {
      this.courseId = event[0];
    },
    validateForm() {
      if (this.curso_titulo === "") {
        this.formError = true;
        this.messageError = this.$t("errors.fieldOb");
        return false;
      }
      if (this.curso_email === "") {
        this.formError = true;
        this.messageError = this.$t("errors.fieldOb");
        return false;
      }
      if (!this.validatePassword()) {
        this.formError = true;
        this.messageError = this.$t("configuration.messageError2");
        return false;
      }
      if (this.curso_max === "") {
        this.formError = true;
        this.messageError = this.$t("errors.fieldOb");
        return false;
      }
      return true;
    },
    cleanForms() {
      this.curso_id = "";
      this.curso_titulo = "";
      this.curso_email = "";
      this.curso_clave = "";
      this.loading = false;
      this.formError = false;
      this.messageError = "";
      this.curso_categoria_cd = "";
      this.curso_precio = "";
      this.curso_duracion = "";
      this.curso_descripcion = "";
      this.curso_desc_larga = "";
      this.curso_conocimientos_previos = "";
      this.curso_idioma = "ESP";
      this.curso_web = 0;
      this.curso_aviso = 0;
      this.curso_inicio = "";
      this.curso_fin = "";
      this.curso_max = "";
      this.getCourses();
    },
    getActions(act, item) {
      this.action = act;
      if (act === 0) {
        return;
      }
      if (act === 1 && item) {
        this.course = item;
        this.curso_id = item.curso_id;
        this.curso_titulo = item.curso_titulo;
        this.curso_email = item.curso_email;
        this.curso_clave = item.curso_clave;
        this.curso_categoria_cd = item.curso_categoria_cd;
        this.curso_precio = item.curso_precio;
        this.curso_duracion = item.curso_duracion;
        this.curso_descripcion = item.curso_descripcion;
        this.curso_desc_larga = item.curso_desc_larga;
        this.curso_conocimientos_previos = item.curso_conocimientos_previos;
        this.curso_idioma = item.curso_idioma;
        this.curso_inicio = this.reformatDate(item.curso_inicio);
        this.curso_fin = this.reformatDate(item.curso_fin);
        this.curso_max = item.curso_max;
        // console.log(this.curso_categoria_cd);
        if (this.curso_categoria_cd > 0) {
          this.curso_web = true;
        } else {
          this.curso_web = false;
        }
        return;
      }
      if (item.curso_id) {
        this.id = item.curso_id;
      }
    },
    seeTemas(id) {
      if (window.location.href.indexOf("adminsu") > 0) {
        this.$router.push({
          path: `/adminsu/mthemes/${id}`,
        });
      } else {
        this.$router.push({
          path: `/admin/mthemes/${id}`,
        });
      }
    },
    seeMaterial(id) {
      if (window.location.href.indexOf("adminsu") > 0) {
        this.$router.push({
          path: `/adminsu/mmaterial/${id}`,
        });
      } else {
        this.$router.push({
          path: `/admin/mmaterial/${id}`,
        });
      }
    },
    seeCurse(id) {
      // console.log(window.location.href.indexOf("adminsu"));
      let routeUrl;
      if (window.location.href.indexOf("adminsu") > 0) {
        routeUrl = this.$router.resolve({
          path: `/adminsu/mcourse/${id}`,
        });
        window.open(routeUrl.href, "_blank");
      } else {
        routeUrl = this.$router.resolve({
          path: `/admin/mcourse/${id}`,
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    validatePassword() {
      if (this.curso_clave && this.curso_clave.length >= 8) {
        let mayuscula = false;
        let minuscula = false;
        let numero = false;
        let caracter_raro = false;

        for (var i = 0; i < this.curso_clave.length; i++) {
          if (
            this.curso_clave.charCodeAt(i) >= 65 &&
            this.curso_clave.charCodeAt(i) <= 90
          ) {
            mayuscula = true;
          } else if (
            this.curso_clave.charCodeAt(i) >= 97 &&
            this.curso_clave.charCodeAt(i) <= 122
          ) {
            minuscula = true;
          } else if (
            this.curso_clave.charCodeAt(i) >= 48 &&
            this.curso_clave.charCodeAt(i) <= 57
          ) {
            numero = true;
          } else {
            caracter_raro = true;
          }
        }
        if (
          mayuscula == true &&
          minuscula == true &&
          caracter_raro == true &&
          numero == true
        ) {
          return true;
        }
      }
      return false;
    },
    formatDate(date) {
      if (date) {
        const auxDate = date.split("-");
        return auxDate[2] + "/" + auxDate[1] + "/" + auxDate[0];
      }
      return "";
    },
    reformatDate(date) {
      if (date) {
        const auxDate = date.split("T");
        return auxDate[0];
      }
      return "";
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.field-icon {
  float: right;
  margin-top: -69px;
  position: relative;
  z-index: 2;
  left: -11px;
  cursor: pointer;
}

.btn {
  float: right;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #333;
  color: #fff;
  font-size: 14px;
  position: absolute;
  padding: 10px 20px;
  bottom: 2em;
  left: -5em;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  border-radius: 6px;
}

[data-title] {
  position: relative;
}
</style>